<template>
  <div>
    <Menu />
    <div class="validador_">
      <section class="validador">
        <h1 class="validador__h1">Validador de Certificados</h1>
        <form class="container validador__form" @submit.prevent="onSubmit">
          <div
            class="form-group"
            :class="{ 'form-group--error': $v.form.doc.$error }"
          >
            <label class="validador__label" for="document"
              >Número de Documento</label
            >
            <input
              class="form-control validador__input"
              type="number"
              v-model.trim="$v.form.doc.$model"
            />
          </div>
          <div v-if="$v.form.doc.$model === ''">
            <div class="error" v-if="!$v.form.doc.required">
              Campo requerido.
            </div>
          </div>
          <div class="error" v-if="!$v.form.doc.maxLength">
            Maximo {{ $v.form.doc.$params.maxLength.max }} carácteres.
          </div>
          <div class="form-group validador__submit">
            <button
              class="form-control validador__btn-submit"
              @click="onSubmit"
              :disabled="submitStatus === 'PENDING'"
            >
              Enviar!
            </button>
          </div>
          <div v-if="!sent" class="typo__p form__success form-footer">
            <span class="col alert alert-danger" v-if="submitStatus === 'ERROR'"
              ><i class="fal fa-times"></i> Por favor llené correctamente el
              formulario.
            </span>
          </div>
          <div
            v-if="submitStatus === 'DATA_EMPTY'"
            class="typo__p form__success form-footer"
          >
            <span class="col alert alert-danger"
              ><i class="fal fa-graduation-cap"></i> No se encontraron
              certificados.
            </span>
          </div>
          <div v-if="!sent" class="typo__p form__success form-footer">
            <span
              class="col alert alert-warning"
              v-if="submitStatus === 'PENDING'"
              ><i class="fal fa-spinner"></i> Buscando...
            </span>
          </div>
        </form>
      </section>
      <section class="resultados">
        <div class="container resultados__container">
          <div class="row">
            <div
              class="col resultados__table table-responsive-md"
              id="content_table"
            >
              <table v-show="data_empty" class="table table-striped">
                <thead class="thead-dark thead-sm">
                  <tr>
                    <th scope="col">Consecutivo</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Curso</th>
                    <th scope="col">Certificación</th>
                    <th scope="col">Vencimiento</th>
                    <th scope="col">Descarga</th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr
                    v-for="certificado in certificados"
                    v-bind:key="certificado.consecutivo"
                  >
                    <td data-label="Consecutivo" align="center">
                      {{ certificado.consecutivo }}
                    </td>
                    <td data-label="Nombre">{{ certificado.nombres }}</td>
                    <td data-label="Curso">{{ certificado.curso }}</td>
                    <td data-label="Certificación" align="center">
                      {{ certificado.certificacion }}
                    </td>
                    <td data-label="Vencimiento" align="center">
                      {{ certificado.vencimiento }}
                    </td>
                    <td
                      v-if="certificado.habilitar"
                      data-label="Descarga"
                      align="center"
                    >
                      <a
                        v-if="
                          certificado.Empresa_cert == 'GRICOMPANY' ||
                            certificado.Empresa_cert == 'ACCION INTEGRAL' ||
                            certificado.Empresa_cert == 'RISSK GROUP'
                        "
                        :href="
                          certificado.Empresa_cert == 'GRICOMPANY'
                            ? 'https://www.gricompany.co/#certificados'
                            : certificado.Empresa_cert == 'ACCION INTEGRAL'
                            ? 'http://accionintegral.com.co/Cursos/ConsultarCursos'
                            : 'https://www.risskgroup.com'
                        "
                        target="_blank"
                      >
                        <i class="fal fa-external-link"></i>
                      </a>
                      <a
                        v-else
                        :href="
                          'https://' +
                            certificado.empresa +
                            '.qinspecting.com/certificado1.php?idcap=' +
                            certificado.id_Capacitacion +
                            '&user=' +
                            certificado.Pers_NumeroDoc +
                            '&empresa=' +
                            certificado.empresa
                        "
                        target="_blank"
                        ><i class="fal fa-download"></i
                      ></a>
                    </td>
                    <td
                      v-if="!certificado.habilitar"
                      data-label="Descarga"
                      align="center"
                    >
                      <i class="deshabilitado fal fa-download"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>

<script>
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import {
  required,
  email,
  minLength,
  maxLength,
  between,
  submitStatus,
} from "vuelidate/lib/validators";
import axios from "axios";

const moment = require("moment");
require("moment/locale/es");

export default {
  name: "Validador",
  components: {
    Menu,
    Footer,
  },
  data() {
    return {
      form: {
        doc: null,
      },
      submitStatus: null,
      sent: false,
      data_empty: false,
      certificados: [],
    };
  },
  validations: {
    form: {
      doc: { required, minLength: minLength(5), maxLength: maxLength(10) },
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        setTimeout(() => {
          try {
            axios({
              method: "post",
              url: "https://apis.qinspecting.com/newapp/validador_certificados",
              data: {
                Pers_NumeroDoc: this.form.doc,
              },
            })
              .then((resp) => resp.data)
              .then((certificates) => {
                if (certificates.length === 0) {
                  this.data_empty = false;
                  this.submitStatus = "DATA_EMPTY";
                } else if (certificates.length > 0) {
                  this.data_empty = true;
                  this.form.doc = null;
                  this.certificados = certificates;
                }
              })
              .catch((error) => {
                console.log("Error en la peticion");
                console.log(error);
              });
            // this.table = true;
          } catch (e) {
            console.log(e);
          }
          this.submitStatus = "OK";
        }, 500);
      }
    },
  },
  metaInfo() {
    return {
      title: "Qinspecting |",
      titleTemplate: "%s Validador de cursos certificados!",
      meta: [
        {
          name: "description",
          content:
            "Qinspecting | Validador de certificados | Valide y descargue fácilmente los certificados de sus cursos desde nuestro sitio web.",
        },
        {
          name: "keywords",
          content:
            "Qinspecting, checklist, preoperacionales, alistamientos, Transportes, Inspecciones, ChecklistElectronicos, Truck, mantenimiento, operaciones, hseq , talentohumano, gerencia, protocolodebioseguridad, protocolos, bioseguridad, Appears, Qinspecting",
        },
        {
          name: "author",
          content: "Appears",
        },
        {
          name: "robots",
          content: "Index, Follow",
        },
        // Tags twitter
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@appears",
        },
        {
          name: "twitter:creator",
          content: "@appears",
        },
        {
          name: "twitter:title",
          content: "Qinspecting | Validador de cursos certificados!",
        },
        {
          name: "twitter:description",
          content:
            "Qinspecting | Validador de certificados | Valide y descargue fácilmente los certificados de sus cursos desde nuestro sitio web.",
        },
        {
          name: "twitter:image",
          content: "https://qinspecting.com/img/qinspecting.svg",
        },
        // Tags facebook
        {
          property: "og:locale",
          content: "es_CO",
        },
        {
          property: "og:site_name",
          content: "Qinspecting",
        },
        {
          property: "og:url",
          content: "https://www.qinspecting.com",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "Qinspecting | Validador de cursos certificados!",
        },
        {
          property: "og:description",
          content:
            "Qinspecting | Validador de certificados | Valide y descargue fácilmente los certificados de sus cursos desde nuestro sitio web.",
        },
        {
          property: "og:image",
          content: "https://qinspecting.com/img/qinspecting.svg",
        },
        {
          property: "og:image:secure_url",
          content: "https://qinspecting.com/img/qinspecting.svg",
        },
        {
          property: "og:image:width",
          content: "600",
        },
        {
          property: "og:image:height",
          content: "323",
        },
      ],
    };
  },
};
</script>

<style scoped>
.fa-download {
  font-size: 25px;
  color: var(--primary);
}

.fa-external-link {
  font-size: 25px;
  color: var(--primary);
}

.deshabilitado {
  color: var(--gray);
}

.alert-warning .form-footer {
  display: flex;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.alert-danger,
.alert-warning {
  display: block;
}

.error {
  color: red;
  font-size: 13px;
}

.validador_ {
  background-image: url("../assets/img/back.svg");
  background-repeat: no-repeat;
  background-image: fixed;
  /* background-image: center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: inherit;
}

.validador {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid #e4e4e4;
  /* background: antiquewhite; */
}

.alert-warning {
  border: 1px solid var(--secondary);
}

.validador__h1 {
  color: var(--secondary);
  font-size: 35px;
  font-weight: 500;
  text-align: center;
}
.validador__form {
  padding: 50px 0 25px 0;
  width: 310px;
  text-align: center;
}

.validador__label {
  font-weight: 500;
}

.validador__submit {
  display: grid;
  justify-items: center;
}

.validador__input {
  background: var(--white-secondary);
  border-radius: 0;
  height: 50px;
}

.validador__btn-submit {
  color: var(--white);
  background: var(--secondary);
  height: 50px;
  border: none;
  border-radius: 0;
}

/* .resultados {
  background: antiquewhite;
} */

.resultados__container {
  max-width: max-content;
}

.resultados__table {
  margin-bottom: 50px;
}

@media screen and (max-width: 1199px) {
  .resultados__table {
    overflow-x: scroll;
    padding: 0;
  }
}

.table {
  background: var(--white);
}

@media screen and (max-width: 650px) {
  table {
    border: 0;
  }

  table tbody tr {
    /* margin-bottom: 10px; */
    display: block;
    border-bottom: 1px solid var(--black);
  }

  table tbody td {
    display: block;
    text-align: right;
    font-size: 16px;
    border-bottom: 1px dotted var(--gray);
  }

  table tbody td:last-child {
    border-bottom: 0;
  }

  table tbody td:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: 600;
  }

  table thead {
    display: none;
  }
}
</style>
